.App {
  /* background-color: #F5F5F5; */
  height: "100%";
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}

.DecisionTree {
  width: 60%;
  margin: auto;
}

.full {
  width: 60%;
  margin: auto;
}

.card {
  text-align: left;
}